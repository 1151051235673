import "./formTemplatesPage.scss";
import { BsSearch } from "react-icons/bs";
import { useEffect, useState, useContext } from "react";
import useApiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import RessourceCard from "../../components/common/RessourceCard";
import { useNavigate } from "react-router-dom";
import { ContractTemplateEntity, TypeLevel1Entity } from "../../domain/entities";
import { FormTemplateClient } from "../../services/api/FormTemplateClient";
import { useTranslation } from "../../contexts/TranslationProvider";
import { getName } from "../../helpers/Translation";
import { LoadingTemplateContext } from "../../contexts/LoadingTemplateContext";
import { all } from "axios";
import { toast } from "react-toastify";
import ConfirmDeleteFormTemplate from "../../components/modals/ConfirmDeteleFormTemplate";
import AdminRessourceCard from "../../components/common/newDesign/AdminRessourceCard";
import LayoutFilter from "../../components/LayoutFilter";
import TemplateTableItem from "../../components/common/newDesign/AdminRessourceCard/TemplateTableItem";

function FormTemplatesPage() {
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState<ContractTemplateEntity[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const formTemplateClient = new FormTemplateClient(apiClient)
  const { loadingTemplates } = useContext(LoadingTemplateContext)
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<ContractTemplateEntity | null>(null);
  const [displayCards, setDisplayCards] = useState("grid");


  useEffect(() => {
    (async () => {
      try {
        await fetchTemplates();
        setLoading(false); // Set loading to false after contracts are fetched
      } catch (error) {
        console.error("Error fetching contracts: ", error);
        setLoading(false); // Also set loading to false in case of error
      }
    })();
  }, []);
  const fetchTemplates = async () => {
    const allTemplates = await formTemplateClient.getAll();
    setTemplates(allTemplates.rows);
  }

  const filteredTemplates = templates?.filter((d) =>
    d!.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const navigate = useNavigate();
  const handleClick = (templateId) => {
    navigate(`/edition-form-template/${templateId}`)
  };
  const sortedContracts = filteredTemplates.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );
  const templatesPerLevel1: Record<TypeLevel1Entity['name'], ContractTemplateEntity[]> = {}

  const levels1Names = [...new Set(filteredTemplates.map(template => getName(template.level1, language)))]
  levels1Names.forEach(level1Name => templatesPerLevel1[level1Name] = [])

  sortedContracts.forEach(template => {
    templatesPerLevel1[getName(template.level1, language)].push(template)
  })
  const handleDelete = async (id) => {
    let deleted = false;
    ({ deleted } = await formTemplateClient.delete(id));
    if (deleted) {
      await fetchTemplates();
      toast.success(t('Template deleted successfully'));
    }
  }
  const handleDesactivate = async (id) => {
    try{
      const deactivated = await formTemplateClient.deactivate(id).then(async () => {
        await fetchTemplates();
        toast.success(t('Template deactivated successfully'));
      });
    }catch(error){
      console.error("Error desactivating template: ", error);
    }
  }
  const handleLayoutChange = (layout) => {
    setDisplayCards(layout);
  };
  return (
    <div className="templates px-5">
      <section id="templates" className="pt-2">
        <span className="current-pathname">
       { t('pages.templates.formTitle')}
        </span>
        <div className="search-zone">
          <div className="d-flex mt-4 ps-1 align-items-center justify-content-between">
            <div className="search-bar w-75">
              <BsSearch className="search-icon" style={{left:language=="ar"&&"84px"}} />
              <input
                type="text"
                placeholder= {t('pages.templates.search.placeholder')}
                className="search-input"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
            <div className="filter d-flex gap-4 align-items-center">

            </div>
          </div>
        </div>
        <LayoutFilter onLayoutChange={handleLayoutChange} />
        <div className="mt-5">
          {loading ? (
            <Loading height="75vh" />
          ) :displayCards=="grid" ?(
            <div className="templates-list-container">
              {levels1Names?.map(level1Name => (
                <div className="templates-space" key={level1Name}>
                  <h3 className="m-3 templates-space-title">{level1Name}</h3>
                  <div className="templates-per-level">
                  {templatesPerLevel1[level1Name]
                      .slice() 
                      .sort((a, b) => (a.updatedAt as any) -(b.updatedAt as any)) 
                      .map((item) => {
                        const isLoading = loadingTemplates.some(template => template.id === item.id && template.type === 'FormTemplate')
                        return (
                        <div key={item.id} className="m-2" id={`template ${item.id}`}>
                          <AdminRessourceCard
                            data={{ type: 'FormTemplate', ...item }}
                            onClick={() => handleClick(item.id)}
                            onDelete={() => {setConfirmDeleteVisible(true); setTemplateToDelete(item)}}
                            onDesactivate={item.activated ? () => handleDesactivate(item.id) : undefined}
                            loading={isLoading}
                          />
                        </div>
                      )
                      })}
                  </div>
                </div>
              ))}

            </div>
          ):
          <table className="template-table">
          <thead>
            <tr>
              <th className={language === "ar" ? "text-right" : ""}>
                {t("pages.groupContracts.tableHeaders.group")}
              </th>
              <th className={language === "ar" ? "text-right" : ""}>
                {t("pages.groupContracts.tableHeaders.name")}
              </th>
              <th className={language === "ar" ? "text-right" : ""}>
                {t("pages.groupContracts.tableHeaders.type")}
              </th>
              <th className={language === "ar" ? "text-right" : ""}>
                {t("pages.groupContracts.tableHeaders.status")}
              </th>
              <th className={language === "ar" ? "text-right" : ""}>
                {t("pages.groupContracts.tableHeaders.actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            <div>
      {levels1Names.map((level1Name) =>
        templatesPerLevel1[level1Name]
          .slice()
          .sort(
            (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          )
          .map((item) => {
            const isLoading = loadingTemplates.some(
              (template) =>
                template.id === item.id && template.type === "ContractTemplate"
            );
            return (
              <TemplateTableItem
              data={{ type: 'FormTemplate', ...item }}
              onClick={() => handleClick(item.id)}
              onDelete={() => {setConfirmDeleteVisible(true); setTemplateToDelete(item)}}
              onDesactivate={item.activated ? () => handleDesactivate(item.id) : undefined}
              loading={isLoading}
              group={level1Name}
              />
            );
          })
      )}
      </div>
    </tbody>
    
        </table>
          }
        </div>
      </section>
      {confirmDeleteVisible && templateToDelete && (
        <ConfirmDeleteFormTemplate  reloadTemplates={fetchTemplates} onClose={setConfirmDeleteVisible} template={templateToDelete} />
      )}
    </div>
  );
}

export default FormTemplatesPage;
