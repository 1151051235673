import React from "react";
import { useTranslation } from "../../contexts/TranslationProvider";
import "./groupProjects.scss"
import ProjectItemTable from "../../components/common/newDesign/ProjectCard/ProjectItemTable";

function GroupProjectsTableItem({ projects, groups, reloadProjects }) {
  const { t, language } = useTranslation();
  

  const sortedProjects = React.useMemo(() => projects.map(c=>({...c, type: "Project"})).sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()), [projects]);

  return (
    <section id="projects-list" className="group-projects-table">
      <table className="ressources-table">
        <thead>
          <tr>
            <th  className={`${language === "ar" && "text-right"}`}>{t("pages.groupContracts.tableHeaders.group")}</th>
            <th  className={`${language === "ar" && "text-right"}`}>{t("pages.groupContracts.tableHeaders.name")}</th>
            <th  className={`${language === "ar" && "text-right"}`}>{t("pages.groupContracts.tableHeaders.type")}</th>
            <th  className={`${language === "ar" && "text-right"}`}>{t("pages.groupContracts.tableHeaders.actions")}</th>
          </tr>
        </thead>
        <tbody>
        <div>
          {groups.map((group) => {
            const groupRessources = sortedProjects.filter(
              (project) => project.assignedToGroupId === group.id
            );
            return groupRessources.map((project) => (
              <ProjectItemTable
              data={project}
              group={group.name}
              setProjectListChanged={reloadProjects}
            />
            ));
          })}
          </div>
        </tbody>
        </table>
    </section>
  );
}

export default GroupProjectsTableItem;
