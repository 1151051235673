import React, { useState, useMemo } from "react";
import "./projectTemplateCard.scss";
import project from "../../../../assets/new-design-icons/Projects.svg";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { formatDate, getTypeLevels } from "../../../../helpers/helper";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteMessage from "../../../modals/ConfirmDeteleMessage";
import { FiMoreHorizontal } from "react-icons/fi";
import ProjectTemplateCardActionsModal from "../../../modals/ProjectTemplateCardActionsModal";

function ProjectTemplateTableItem({
  data,
  setProjectListChanged,
  onDesactivate,
  group = null,
}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const translationPathStatus = "pages.EditProjectTemplate.";
  const translationPath = "pages.ressources.";
  const [confirmDeleteMessage, setConfirmDelete] = useState(false);
  const { t, language } = useTranslation();
  const projectIcon = project.toString();
  const navigate = useNavigate();
  const onClick = () => {
    if (data && data.id) {
      navigate(`/edition-project-template/${data.id}`);
    }
  };
  const onDelete = () => {
    setConfirmDelete(true);
  };
  const typeDisplay = useMemo(() => {
    return getTypeLevels(data)
      .map((level) => {
        const translatedName = level.translations.find(
          (t) => t.language === language
        );
        if (translatedName) {
          return translatedName.name;
        } else {
          return level.name;
        }
      })
      .join("/");
  }, [data, language]);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <>
      {confirmDeleteMessage && (
        <ConfirmDeleteMessage
          onClose={() => setConfirmDelete(false)}
          messageDelete={t("pages.viewProject.deleteMessage")}
          projectTemplate={data}
          setNewChange={setProjectListChanged}
        />
      )}
      <tr
        onClick={onClick}
        key={data.id + "-" + data.type}
      >
        <td className={`${language === "ar" && "text-right"}`}>{group}</td>
        <td className={`${language === "ar" && "text-right"}`}>
          <div className="title-table-item">
            <img
              src={projectIcon}
              alt="icon"
              style={{ width: "30px", height: "30px" , marginRight:"15px"}}
            />
            <div className="">{data.name}</div>
          </div>
        </td>
        <td className={`${language === "ar" && "text-right"}`}>{typeDisplay}</td>

   
        <td className={`${language === "ar" && "text-right"}`}>
          <div className={`${data.activated ? "status-active" : "status"}`}>
            {" "}
            {data?.activated
              ? t(translationPathStatus + "status.active")
              : t(translationPathStatus + "status.inactive")}
          </div>
        </td>
        <td className={`${language === "ar" && "text-right"} actions`}>
          <button className="plus-icon" onClick={(e) => togglePopup(e)}>
            <FiMoreHorizontal size={20} />
          </button>
          {isPopupOpen && (
            <div className="popup">
              <ProjectTemplateCardActionsModal
                onView={onClick}
                onDelete={onDelete}
                onDesactivate={onDesactivate}
              />
            </div>
          )}
        </td>
      </tr>
    </>
  );
}

export default ProjectTemplateTableItem;
