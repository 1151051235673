import React, { useState, useMemo } from "react";
import "./projectCard.scss";
import project from "../../../../assets/new-design-icons/Projects.svg";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { getTypeLevels } from "../../../../helpers/helper";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteMessage from "../../../modals/ConfirmDeteleMessage";
import { FiMoreHorizontal } from "react-icons/fi";
import ContractCardActionsModal from "../../../modals/ContractCardActionsModal";

function ProjectItemTable({ data, group = null, setProjectListChanged }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [confirmDeleteMessage, setConfirmDelete] = useState(false);
  const { t, language } = useTranslation();
  const projectIcon = project.toString();
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/projet/${data?.id}`);
  };
  const onDelete = () => {
    setConfirmDelete(true);
  };
  const onEdit = () => {
    navigate(`/edit-project/${data?.id}`);
  };
  const beneficialProfilePhoto = data?.ressource?.beneficials?.filter(
    (b) => b.profilePhoto
  )?.[0]?.profilePhoto;
  const typeDisplay = useMemo(() => {
    return getTypeLevels(data)
      .map((level) => {
        const translatedName = level.translations.find(
          (t) => t.language === language
        );
        if (translatedName) {
          return translatedName.name;
        } else {
          return level.name;
        }
      })
      .join("/");
  }, [data, language]);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <>
      {confirmDeleteMessage && (
        <ConfirmDeleteMessage
          onClose={() => setConfirmDelete(false)}
          messageDelete={t("pages.viewProject.deleteMessage")}
          project={data}
          setNewChange={setProjectListChanged}
        />
      )}
      <tr
        onClick={onClick}
        key={data.id + "-" + data.type}
      >
        {group && (
          <td className={`${language === "ar" && "text-right"}`}>
            {group || t("noGroup")}
          </td>
        )}
        <td className={`${language == "ar" && "text-right"}`}>
          <div className="title-table-item">
            <img
              src={projectIcon}
              alt="icon"
              style={{ width: "30px", height: "30px" }}
            />
            <div style={{ marginLeft: "20px" }}>{data.name}</div>
          </div>
        </td>
        <td className={`${language == "ar" && "text-right"}`}>{typeDisplay}</td>
        <td className={`${language == "ar" && "text-right"}`}>
          <div className="actions">
            <button className="plus-icon" onClick={(e) => togglePopup(e)}>
              <FiMoreHorizontal size={20} />
            </button>
            {isPopupOpen && (
              <div className="popup">
                <ContractCardActionsModal
                  onView={onClick}
                  onDelete={onDelete}
                  onEdit={onEdit}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
}

export default ProjectItemTable;
