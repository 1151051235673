import { RenderSegment } from "../domain/types/ClauseParams";

export const renderStyledSegment = (segment: RenderSegment) => {
  const { value, style } = segment; // Assuming each segment has 'text' and 'style'
  const contentWithBreaks = value?.split(/(\r\n|\r|\n|<br\s*\/?>)/g).map((part, index) => {
    if (part.match(/(\r\n|\r|\n|<br\s*\/?>)/)) {
      return <br key={index} />;
    }
    return <>{part}</>;
  });
  let content: any = contentWithBreaks;
  if(!style){
    return content;
  }
  // Wrap with the appropriate tags based on style
  if (style.bold) {
    content = <strong>{content}</strong>;
  }
  if (style.italic) {
    content = <em>{content}</em>;
  }
  if (style.underline) {
    content = <u>{content}</u>;
  }

  return content;
};