import React, { useState, useEffect, useMemo } from "react";
import "./ressource.scss";
import ContractCardActionsModal from "../../../modals/ContractCardActionsModal";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { FiMoreHorizontal } from "react-icons/fi";
import form from "../../../../assets/new-design-icons/Form.svg";
import contract from "../../../../assets/new-design-icons/Contract.svg";
import calculate from "../../../../assets/new-design-icons/Calculate.svg";
import chat from "../../../../assets/new-design-icons/Chat.svg";
import { ar, fr, enUS } from "date-fns/locale";
import { formatDistanceToNow } from "date-fns";
import { getTypeLevels } from "../../../../helpers/helper";
import FormBody from "./content/FormBody";
import ContractBody from "./content/ContractBody";
import DocumentBody from "./content/DocumentBody";
import { LoadingRessourceContext } from "../../../../contexts/LoadingRessourceContext";
import Loading from "../../Loading";
const aiIcon = require("../../../../assets/new-design-icons/ai.png");
const docIcon = require("../../../../assets/new-design-icons/doc.png");

function RessourceTableItem({ data, group=null,onDelete, onClick }) {
  const formIcon = form.toString();
  const contractIcon = contract.toString();
  const [updatedSince, setUpdatedSince] = useState("");
  const [createdSince, setCreatedSince] = useState("");
  const { loadingRessources } = React.useContext(LoadingRessourceContext);
  const isLoading = loadingRessources.some(
    (ressource) => ressource.id === data.id && ressource.type === data.type
  );
  const calculateIcon = calculate.toString();
  const chatIcon = chat.toString();
  const { t, language } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  const typeDisplay = useMemo(() => {
    if (data.type === "Summary") {
      const level1 = data.summary.level1 || null;
      const level2 = data.summary.level2 || null;
      const level3 = data.summary.level3 || null;
      return [level1, level2, level3]
        .filter((level) => level !== null)
        .map((level) => {
          const translatedName = level.translations.find(
            (t) => t.language === language
          );
          if (translatedName) {
            return translatedName.name;
          }
          return level.name;
        })
        .join("/");
    } else {
      return getTypeLevels(data)
        .map((level) => {
          const translatedName = level.translations.find(
            (t) => t.language === language
          );
          if (translatedName) {
            return translatedName.name;
          } else {
            return level.name;
          }
        })
        .join("/");
    }
  }, [data, language]);

  useEffect(() => {
    const updateDate = new Date(data?.updatedAt);
    const creationDate = new Date(data?.createdAt);
    if (Date.parse(updateDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar,
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(updateDate, { addSuffix: true, locale });
      setUpdatedSince(time);
    }
    if (Date.parse(creationDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar,
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(creationDate, {
        addSuffix: true,
        locale,
      });
      setCreatedSince(time);
    }
  }, [data, language]);
  const bodyRender = (data, typeDisplay) => {
    switch (data.type) {
      case "Form":
        return (
          <FormBody
            type={typeDisplay}
            data={data}
            updatedSince={updatedSince}
            createdSince={createdSince}
          />
        );
      case "Contract":
      case "ContractAI":
      case "Summary":
        return (
          <ContractBody
            type={typeDisplay}
            data={data}
            updatedSince={updatedSince}
            createdSince={createdSince}
          />
        );
      case "Document":
        return (
          <DocumentBody
            data={data}
            updatedSince={updatedSince}
            createdSince={createdSince}
          />
        );
      default:
        return null;
    }
  };
  const handleClick = () => {
    if (isLoading) return;
    onClick();
  };
  return (
    <tr
      onClick={handleClick}
      key={data.id + "-" + data.type}
      className=""
    >
         {group && (
        <td className={`${language === "ar" && "text-right"}`}>
          {group || t("noGroup")}
        </td>
      )}
      <td className={`${language == "ar" && "text-right"}`}>
        <div className="title-table-item">
        {data.type === "Form" ? (
          <img
            src={formIcon}
            alt="icon"
            style={{ width: "30px", height: "30px" }}
          />
        ) : data.type === "Contract" ||
          data.type === "ContractAI" ||
          data.type === "Summary" ? (
          <img
            src={contractIcon}
            alt="icon"
            style={{ width: "30px", height: "30px" }}
          />
        ) : data.type === "Document" ? (
          <img
            src={docIcon}
            alt="icon"
            style={{ width: "25px", height: "25px" }}
          />
        ) : (
          <></>
        )}
        <div style={{ marginLeft: "20px" }}>{data.name}</div>
        </div>
      </td>
      <td className={`${language == "ar" && "text-right"}`}>{typeDisplay}</td>
      <td className={`${language == "ar" && "text-right"}`}>
        {" "}
        {data.status || isLoading ? (
          <div className="status">
            {isLoading ? <Loading /> : t("status." + data.status)}
          </div>
        ) : (
          ""
        )}
      </td>
      <td className={`${language == "ar" && "text-right"}`}>
        <div className="actions">
          <button className="plus-icon" onClick={(e) => togglePopup(e)}>
            <FiMoreHorizontal size={20} />
          </button>
          {isPopupOpen && (
            <div className="popup">
              <ContractCardActionsModal
                onView={handleClick}
                onDelete={onDelete}
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}

export default RessourceTableItem;
