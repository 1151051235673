import React, { useState, useEffect, useMemo } from "react";
import "./ressource.scss";
import { useTranslation } from "../../contexts/TranslationProvider";
import form from "../../assets/new-design-icons/Form.svg";
import contract from "../../assets/new-design-icons/Contract.svg";
import { ar, fr, enUS } from "date-fns/locale";
import { formatDistanceToNow } from "date-fns";
import { getTypeLevels } from "../../helpers/helper";

const docIcon = require("../../assets/new-design-icons/doc.png");

function RessourceRequireTableItem({ data, onDelete, onClick }) {
  const formIcon = form.toString();
  const contractIcon = contract.toString();
  const [updatedSince, setUpdatedSince] = useState("");
  const [createdSince, setCreatedSince] = useState("");

  const { t, language } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  const typeDisplay = useMemo(() => {
    if (data.type === "SUMMARY") {
      const level1 = data.summary.level1 || null;
      const level2 = data.summary.level2 || null;
      const level3 = data.summary.level3 || null;
      return [level1, level2, level3]
        .filter((level) => level !== null)
        .map((level) => {
          const translatedName = level.translations.find(
            (t) => t.language === language
          );
          if (translatedName) {
            return translatedName.name;
          }
          return level.name;
        })
        .join("/");
    } else {
      return getTypeLevels(data)
        .map((level) => {
          const translatedName = level.translations.find(
            (t) => t.language === language
          );
          if (translatedName) {
            return translatedName.name;
          } else {
            return level.name;
          }
        })
        .join("/");
    }
  }, [data, language]);
  useEffect(() => {
    const updateDate = new Date(data?.updatedAt);
    const creationDate = new Date(data?.createdAt);
    if (Date.parse(updateDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar,
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(updateDate, { addSuffix: true, locale });
      setUpdatedSince(time);
    }
    if (Date.parse(creationDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar,
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(creationDate, {
        addSuffix: true,
        locale,
      });
      setCreatedSince(time);
    }
  }, [data, language]);

  const handleClick = () => {
    onClick();
  };
  return (
    <tr onClick={handleClick} key={data.id + "-" + data.type}     className="ressource-required-card-list">
      <td  className={`${language=="ar"&&"text-right"}`}>
        {data.type === "FORM" ? (
          <img
            src={formIcon}
            alt="icon"
            style={{ width: "30px", height: "30px" }}
          />
        ) : data.type === "CONTRACT" ||
          data.type === "CONTRACTAI" ||
          data.type === "SUMMARY" ? (
          <img
            src={contractIcon}
            alt="icon"
            style={{ width: "30px", height: "30px" }}
          />
        ) : data.type === "DOCUMENT" ? (
          <img
            src={docIcon}
            alt="icon"
            style={{ width: "25px", height: "25px" }}
          />
        ) : (
          <></>
        )}

        <span style={{marginLeft:"20px"}} >{data.name}</span>
      </td>

      <td  className={`${language=="ar"&&"text-right"}`}>
        {data.type != "DOCUMENT" && (
          <span className="value">{typeDisplay}</span>
        )}
      </td>
      <td  className={`${language=="ar"&&"text-right"}`}>
        {" "}
        {data.status ? (
          <div className="status">{t("status." + data.status)}</div>
        ) : (
          ""
        )}
      </td>
      <td  className={`${language=="ar"&&"text-right"}`}><span className="createDocument">{t("pages.viewProject.document.createDocument")}</span></td>
    </tr>
  );
}

export default RessourceRequireTableItem;
