import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/TranslationProvider";
import apiClientWithLoading from "../../services/api/ApiClient";
import { ContractClient } from "../../services/api/ContractClient";
import { FormClient } from "../../services/api/FormClient";
// import RessourceTableItem from "../Project/RessourceTableItem";
import ConfirmDeletePopup from "../../components/modals/ConfirmDetelePopup";
import RessourceTableItem from "../../components/common/newDesign/RessourceCard/RessourceTableItem";

function GroupContractTable({ ressources, groups, reloadContracts }) {
  const navigate = useNavigate();
  const { t, language } = useTranslation();
  const apiClient = apiClientWithLoading();
  const contractClient = new ContractClient(apiClient);
  const formClient = new FormClient(apiClient);

  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [ressourceToHandle, setRessourceToHandle] = useState(null);

  const handleClick = (ressource) => {
    const path =
      ressource.type === "Contract"
        ? `/edition-contrat/${ressource.id}`
        : `/edit-form/${ressource.id}`;
    navigate(path);
  };

  const handleDelete = async (ressource) => {
    try {
      const deleteClient =
        ressource.type === "Contract" ? contractClient : formClient;
      const { deleted } = await deleteClient.delete(ressource.id);
      if (deleted) {
        await reloadContracts();
      }
    } catch (err) {
      console.error("Error deleting resource:", err);
    }
  };

  return (
    <section id="#contracts-table" className="contracts-table">
      <table className="ressources-table">
        <thead>
          <tr>
            <th  className={`${language === "ar" && "text-right"}`}>{t("pages.groupContracts.tableHeaders.group")}</th>
            <th  className={`${language === "ar" && "text-right"}`}>{t("pages.groupContracts.tableHeaders.name")}</th>
            <th  className={`${language === "ar" && "text-right"}`}>{t("pages.groupContracts.tableHeaders.type")}</th>
            <th  className={`${language === "ar" && "text-right"}`}>{t("pages.groupContracts.tableHeaders.status")}</th>
            <th  className={`${language === "ar" && "text-right"}`}>{t("pages.groupContracts.tableHeaders.actions")}</th>
          </tr>
        </thead>
        <tbody>
          <div>
          {groups.map((group) => {
            const groupRessources = ressources.filter(
              (ressource) => ressource.assignedToGroupId === group.id
            );
            return groupRessources.map((ressource) => (
              <RessourceTableItem
                key={`${ressource.id}-${ressource.type}`}
                data={ressource}
                group={group.name} 
                onClick={() => handleClick(ressource)}
                onDelete={() => {
                  setRessourceToHandle(ressource);
                  setConfirmDelete(true);
                }}
              />
            ));
          })}
          </div>
        </tbody>
      </table>

      {showConfirmDelete && ressourceToHandle && (
        <ConfirmDeletePopup
          msgDelete={t("pages.contracts.msgDeleteRessource")}
          onClose={() => {
            setConfirmDelete(false);
            setRessourceToHandle(null);
          }}
          entityToDelete={ressourceToHandle.name || ""}
          onDelete={() => handleDelete(ressourceToHandle)}
        />
      )}
    </section>
  );
}

export default GroupContractTable;
