import React, { useState, useMemo } from "react";
import "./ressource.scss";
import ContractCardActionsModal from "../../components/modals/ContractCardActionsModal";
import { useTranslation } from "../../contexts/TranslationProvider";
import { FiMoreHorizontal } from "react-icons/fi";
import form from "../../assets/new-design-icons/Form.svg";
import contract from "../../assets/new-design-icons/Contract.svg";
import { getTypeLevels } from "../../helpers/helper";
import { LoadingRessourceContext } from "../../contexts/LoadingRessourceContext";
import Loading from "../../components/common/Loading";
const aiIcon = require("../../assets/new-design-icons/ai.png");
const docIcon = require("../../assets/new-design-icons/doc.png");

function RessourceTableItem({ data, onDelete, onClick }) {
  const formIcon = form.toString();
  const contractIcon = contract.toString();
  const { loadingRessources } = React.useContext(LoadingRessourceContext);
  const isLoading = loadingRessources.some(
    (ressource) => ressource.id === data.id && ressource.type === data.type
  );
  const { t, language } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  const typeDisplay = useMemo(() => {
    if (data.type === "CONTRACTAI") {
      const level1 = data.level1 || null;
      const level2 = data.level2 || null;
      const level3 = data.level3 || null;
      return [level1, level2, level3]
        .filter((level) => level !== null)
        .map((level) => {
          const translatedName = level.translations.find(
            (t) => t.language === language
          );
          if (translatedName) {
            return translatedName.name;
          }
          return level.name;
        })
        .join("/");
    }
    if (data.type === "SUMMARY") {
      const level1 = data.summary.level1 || null;
      const level2 = data.summary.level2 || null;
      const level3 = data.summary.level3 || null;
      return [level1, level2, level3]
        .filter((level) => level !== null)
        .map((level) => {
          const translatedName = level.translations.find(
            (t) => t.language === language
          );
          if (translatedName) {
            return translatedName.name;
          }
          return level.name;
        })
        .join("/");
    } else {
      return getTypeLevels(data)
        .map((level) => {
          const translatedName = level.translations.find(
            (t) => t.language === language
          );
          if (translatedName) {
            return translatedName.name;
          } else {
            return level.name;
          }
        })
        .join("/");
    }
  }, [data, language]);

  const handleClick = () => {
    if (isLoading) return;
    onClick();
  };
  return (
    <tr
      onClick={handleClick}
      key={data.id + "-" + data.type}
      className="ressource-required-card-list">
      <td  className={`${language=="ar"&&"text-right"}`}>
        <div className="title-table-item">
        {data.type === "FORM" ? (
          <img
            src={formIcon}
            alt="icon"
            style={{ width: "30px", height: "30px" }}
          />
        ) : data.type === "CONTRACT" ||
          data.type === "CONTRACTAI" ||
          data.type === "SUMMARY" ? (
          <img
            src={contractIcon}
            alt="icon"
            style={{ width: "30px", height: "30px" }}
          />
        ) : data.type === "DOCUMENT" ? (
          <img
            src={docIcon}
            alt="icon"
            style={{ width: "25px", height: "25px" }}
          />
        ) : (
          <></>
        )}

        <div style={{marginLeft:"20px"}}>{data.name}</div>
        </div>
      </td>

      <td  className={`${language=="ar"&&"text-right"}`}>{typeDisplay}</td>
      <td  className={`${language=="ar"&&"text-right"}`}>
        {" "}
        {data.status || isLoading ? (
          <div className="status">
            {isLoading ? <Loading /> : t("status." + data.status)}
          </div>
        ) : (
          ""
        )}
      </td>
      <td  className={`${language=="ar"&&"text-right"}`}>
      <div className='actions'>
        <button className="plus-icon" onClick={(e) => togglePopup(e)}>
          <FiMoreHorizontal size={20} />
        </button>
        {isPopupOpen && (
          <div className="popup">
            <ContractCardActionsModal
              onView={handleClick}
              onDelete={onDelete}
            />
          </div>
        )}
        </div>
      </td>
    </tr>
  );
}

export default RessourceTableItem;
