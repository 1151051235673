import "./editForm.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import EditFormContext from "../../../contexts/EditFormContext";
import { calculateFormCompletionPercentage } from "../../../helpers/CompletionPercentage";
import { FormEntity, FormTemplatePageEntity } from "../../../domain/entities";
import EditFormInputList from "./EditFormInputList";
import Loading from "../../../components/common/Loading";
import { useSubmitForm } from "../../../hooks/useSubmitForm";
import EventManager from "../../../services/EventManager";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns';
import { ar, fr, enUS } from 'date-fns/locale';
import { PromiseSub, createPromiseSub } from "../../../utils/promise";
import MiddlewareManager from "../../../services/MiddlewareManager";
import SaveDraftModel from "../../../components/modals/SaveDraftModal";
import { useTranslation } from "../../../contexts/TranslationProvider";
import ProgressBar from "../../../components/common/ProgressBar";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { FormClient } from "../../../services/api/FormClient";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { toast } from "react-toastify";
import { getFormExportData, serializeParamValuesForm } from "../../../hooks/serializeParamValues";
import { saveWorkbook } from "../../../utils/csv";
import { FormDocxExportClient } from "../../../services/api/FormDocxExportClient";
import { downloadFile } from "../../../utils/file";
import { updateForm } from "../../../helpers/UpdateForm";
const { default: EditFormPageIcon } = require('../../../assets/edit_form_page.svg');
const { default: EditFormPageOnIcon } = require('../../../assets/edit_form_page_on.svg');
const { default: VLineIcon } = require('../../../assets/v_line.svg');
const { default: VLineOnIcon } = require('../../../assets/v_line_on.svg');
const formIcon = require("../../../assets/new-design-icons/form-icon.png")




//maintain the state of the fetched contract data and handle the interactions between the ClauseList and VariableInput components.
function EditForm() {
  const location = useLocation();
  const { previousPathname } = location?.state || { previousPathname: [] };
  const context = useContext(EditFormContext);
  const { setLoading } = useContext(LoadingContext);
  const submitForm = useSubmitForm()
  const [draft, setSaveDraft] = useState(false);
  const [sub, setSub] = useState<PromiseSub>(null);
  const { t, language } = useTranslation();
  const translationPath = "pages.editionForm"
  const [timeSinceUpdate, setTimeSinceUpdate] = useState("")


  const apiClient = useApiClientWithLoading(setLoading);
  const {
    form,
    template,
    name,
    paramValues,
    fileNames,
    beneficialsMap,
    completionPercentage,
    setCompletionPercentage,
    selected,
    setProject,
    setRequirement,
    setRequirementType,
    project,
    templateId
  } = context;
  const body: FormEntity = {
    templateId: templateId,
    paramValues: paramValues,
    status: completionPercentage === 100 ? "Done" : "Draft",
    name: name,
  };
  const submitValidationData = async (validationData: {
    validateGroupId: FormEntity['assignedToGroupId'];
    assignToGroupId: FormEntity['assignedToGroupId'];
    assignToUserId: FormEntity['assignedToUserId'];
  }, onClose: () => void, setInvalidData: (v: boolean) => void, setLoading: (v: boolean) => void) => {
    try {
      const formClient = new FormClient(apiClient)
      if (validationData.assignToGroupId && validationData.assignToUserId && validationData.assignToGroupId) {
        setLoading(true);
        const newId = await submitForm(context)
        await formClient.validate(newId, validationData);
        setLoading(false);
        toast.success(t("modals.validateForm.messages.success"));
        if (!context?.project) {
        }
        onClose();
      }
      else {
        setInvalidData(true)
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("modals.validateForm.messages.error"));
      console.error(error);
      onClose();
    }
  };

  const onLocation = async (pathname: string) => {
    if (location.pathname.includes("/edit-form") && !pathname.includes("/edit-form")) {
      const newSub = createPromiseSub()
      setSaveDraft(true);
      setSub(newSub);
      await newSub.promise
    }
  };
  useEffect(() => {
    MiddlewareManager.subscribe('location', onLocation);
    return () => {
      MiddlewareManager.unsubscribe('location', onLocation);
    };
  }, [onLocation]);
  useEffect(() => {
    if (location.pathname !== "/edit-form") {
      setSaveDraft(false);
    }
    return () => { };
  }, []);

  useEffect(() => {
    EventManager.invoke('EditFormContext', context)
    return () => { }
  }, [JSON.stringify(context)])
  useEffect(() => {
    EventManager.subscribe('SubmitForm', submitForm)
    EventManager.subscribe('ValidateForm', submitValidationData)
    return () => {
      EventManager.unsubscribe('SubmitForm', submitForm)
      EventManager.unsubscribe('ValidateForm', submitValidationData)
    }
  }, [])


  useEffect(() => {
    if (location?.state?.project) {
      setProject(location?.state?.project)
      setRequirement(location?.state?.requirement)
      setRequirementType(location?.state?.requirementType)
    }
  }, [project])
  useEffect(() => {
    const updateDate = new Date(template?.updatedAt);
    if (Date.parse(updateDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(updateDate, { addSuffix: true, locale });
      setTimeSinceUpdate(time);
    }
  }, [template, language]);
  const [currentIndexPage, setIndexCurrentPage] = useState(0)
  let index = template?.pages?.length
  let allParams = useMemo(() => {
    let allParams: FormTemplatePageEntity['params'] = []
    template?.pages?.map(page => allParams.push(...page.params))
    return allParams
  }, [template, paramValues])

  useEffect(() => {
    const newCompletionPercentage = calculateFormCompletionPercentage(paramValues, allParams);
    setCompletionPercentage?.(newCompletionPercentage);
  }, [paramValues, allParams]);

  const selectedPageIndex = selected ? template?.pages?.findIndex(page => page.id == selected.pageId) : -1
  const navigate = useNavigate()
  const formClient = new FormClient(apiClient)


  const saveFormAsCSV = async () => {
    try {
      const updatedForm = await updateForm(formClient, form?.id, body, "full")
      const workbook = serializeParamValuesForm(updatedForm.paramValues, template, updatedForm.fileNames, updatedForm.beneficialsMap, t, language)
      saveWorkbook(workbook, `${updatedForm?.name}.xlsx`);

    } catch (error) {
      console.log('error', error);
    }
  };

  const saveFormAsDocx = async () => {
    try {
      const updatedForm = await updateForm(formClient, form?.id, body, "full")

      let formDocxExportId = updatedForm.template?.formDocxExportId
      const formDocxExportClient = new FormDocxExportClient(apiClient)
      if (!formDocxExportId) {
        const defaultFormDocxExport = await formDocxExportClient.getDefault()
        if (!defaultFormDocxExport) {
          toast("No default FormDocxExport")
          return
        }
        formDocxExportId = defaultFormDocxExport.id
      }
      const data = getFormExportData(updatedForm, t, language)
      const url = await formDocxExportClient.dataToDocx(formDocxExportId, data)
      downloadFile(url, `${updatedForm?.name}.docx`)

    } catch (error) {
      console.log('error', error);
    }
  };


  useEffect(() => {
    EventManager.subscribe('ExportCSV', saveFormAsCSV)
    return () => {
      EventManager.unsubscribe('ExportCSV', saveFormAsCSV)
    }
  }, [])
  useEffect(() => {
    EventManager.subscribe('ExportDocx', saveFormAsDocx)
    return () => {
      EventManager.unsubscribe('ExportDocx', saveFormAsDocx)
    }
  }, [])

  return (
    <div className="form-page-edition">
      <div className="px-2 container-paths edit-form-component">
        {previousPathname && previousPathname?.map((item, index) => (
          <span key={index}>
            <span className="previous-pathname" onClick={() => navigate(item.link)}>{item.label}</span>
            <span className="path-separator"> {">"} </span>
          </span>
        ))}
        {previousPathname && previousPathname.length > 0 && <span className="current-pathname">{name}</span>}
      </div>
      <div className="d-flex align-items-start justify-content-between border-bottom w-100 pr-3 pt-2 pb-2 pl-3" style={{ paddingRight: "55px", paddingLeft: "18px" }}>
        <div className='form-title'>
          <div style={{ overflow: "hidden", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "10px" }}>
            <img src={formIcon} alt="form-icon" style={{ width: "175px", height: "175px", marginTop: "80px" }} />
          </div>
          <div className='form-title-details'>
            <span className='title'>
              {name}
            </span>
            <span className='modified'>
              {t(translationPath + ".modified")} : {timeSinceUpdate}
            </span>
          </div>
        </div>
        <p style={{ color: "#8D8E90" }}> {t(translationPath + ".page")} ({currentIndexPage + 1}/{index})</p>
      </div>
      <div className="d-flex">
        <div className="container-steps-form  pt-5 px-5">
          <div>
            <ProgressBar progress={completionPercentage} expanded={false} />
          </div>
          <div className="edit-form-steps col py-5 px-4">
            {template?.pages?.map((page, index) => {
              const isChecked = index <= currentIndexPage
              const isNextChecked = (index + 1) <= currentIndexPage
              return <div key={index} className="edit-form-step d-flex align-items-start gap-2 pt-3 pb-2">
                <img
                  src={isChecked ? EditFormPageOnIcon : EditFormPageIcon}
                  style={{ cursor: "pointer" }}
                  height="24px"
                  width="auto"
                  alt=""
                  onClick={() => setIndexCurrentPage(index)}
                >
                </img>
                {index < template.pages.length - 1 && (
                  <img
                    src={isNextChecked ? VLineOnIcon : VLineIcon}
                    className={`edit-form-step-line ${language == "ar" && "edit-form-step-line-arabic"}`}
                  />
                )}
                {page.name}
              </div>
            }
            )}
          </div>
        </div>
        <div className="edit-form-input-list-container">
          {template ? <EditFormInputList currentPage={template?.pages[currentIndexPage]} setIndexCurrentPage={setIndexCurrentPage} currentIndexPage={currentIndexPage} /> : <Loading />}
        </div>
      </div>
      {draft && (
        <SaveDraftModel
          type="form"
          onClose={() => { setSaveDraft(false); sub.reject() }}
          onSave={(saved: boolean) => { sub.resolve() }}
        />
      )}

    </div>
  );
}
export default EditForm;
