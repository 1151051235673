import { ContractTemplateParsedData, mergeParams, mergeParamsForm } from "../helpers/ParseTemplateData";
import { BeneficialEntity, ContractEntity, FormEntity } from "../domain/entities";
import { cellsToXLSXWorkbook } from "../utils/csv";
import { closeDocxmlText, FormDocxExportText, getRenderedFormTemplate, renderTableToDocXml } from "../domain/Form";
import { getRenderSegments } from "../helpers/segmentation";
import { SegmentedTextType } from "../domain/types/ClauseParams";
import { virtualbeneficialGetters } from "../helpers/Beneficial";
import { LanguageType } from "../contexts/TranslationProvider";

export function serializeParamValues(
	inputValues: ContractEntity['paramValues'],
	templateData: ContractTemplateParsedData,
	fileNames: Record<number, string>,
	beneficialsMap: Record<string, BeneficialEntity>,
	t: (string) => string, language: LanguageType
) {
	const params = mergeParams(templateData)

	let spreadsheets: Record<string, string[][]> = {
		"default": [
			['Clause_name', 'Sub_clause_code', 'Sub_clause_name', 'Name', 'Type', 'Label', 'Value'],
		],
	}
	let paramMap: Record<string, boolean> = {}
	let tableCounter = 1
	for (const param of params) {
		if (paramMap[param.name]) {
			continue
		}
		paramMap[param.name] = true
		const value = inputValues[param.name]
		const { clauseName, subClauseCode, subClauseName, name, type, label } = param
		const row = [clauseName, subClauseCode, subClauseName, name, type, label]
		switch (param.type) {
			case 'beneficial':
				const beneficial = beneficialsMap[value]
				if (beneficial) {
					spreadsheets[virtualbeneficialGetters.fullName(beneficial, t, language)] = serializeBeneficial(beneficial, t, language)
				}
				spreadsheets['default'].push([...row, beneficial ? virtualbeneficialGetters.fullName(beneficial, t, language) : ""])
				break;
			case 'beneficial[]':
				const beneficials = Array.isArray(value) ? value.map(bId => beneficialsMap[bId]) : []
				for (const beneficial of beneficials || []) {
					spreadsheets[virtualbeneficialGetters.fullName(beneficial, t, language)] = serializeBeneficial(beneficial, t, language)
				}
				spreadsheets['default'].push([...row, beneficials?.map(b => `BENEFICIAL-${b.id}`)?.join(", ") ?? ""])
				break;
			case 'table':
				const rows = value ?? []
				spreadsheets[`TABLE-${tableCounter}`] = [
					param.args.map(arg => arg.header),
					...rows
				]
				spreadsheets['default'].push([...row, `TABLE-${tableCounter}`])
				++tableCounter
				break;
			case 'csv':
				const [csvTransposed, csvRows, csvName] = value ?? [false, [], ""]
				spreadsheets[`TABLE-${tableCounter}`] = csvRows
				spreadsheets['default'].push([...row, `TABLE-${tableCounter}`])
				++tableCounter
				break;
			case 'file':
				if (value instanceof File) {
					spreadsheets['default'].push([...row, value.name ?? ""])
				} else {
					const fileName = fileNames[value]
					spreadsheets['default'].push([...row, fileName ?? ""])
				}
				break;
			case 'list':
				spreadsheets['default'].push([...row, value?.map(idx => idx + 1)?.join(",") ?? ""])
				break;
			case 'enum':
				const arg = param.args[value]
				spreadsheets['default'].push([...row, arg?.option ?? ""])
				break;
			case 'boolean':
				spreadsheets['default'].push([...row, (value == true && "Oui") || (value == false && "Non") || ""])
				break;
			case 'comment':
			case 'date':
			case 'number':
			case 'string':
			case 'property':
				spreadsheets['default'].push([...row, value ?? ""])
				break;
			default:
				break;
		}
	}
	return cellsToXLSXWorkbook(spreadsheets)

};
export function serializeBeneficial(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType): string[][] {
	let out: string[][] = []
	//fields
	out.push(
		[t("pages.beneficials.type"), t(`pages.contractTemplateParam.edition.beneficial.${beneficial.type.toLowerCase()}`)],
	)
	if (beneficial.type == 'Person') {
		out.push(
			[t("pages.editionContract.popups.benificial.inputs.person.firstname"), beneficial.firstName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.lastname"), beneficial.lastName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.email"), beneficial.email ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.jobTitle"), beneficial.jobTitle ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.address"), beneficial.address?.addressLine ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.dateOfBirth"), String(beneficial.dateOfBirth) ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.placeOfBirth"), beneficial.placeOfBirth ?? ""],
		)

	} else if (beneficial.type == 'Company') {
		out.push(
			[t("pages.editionContract.popups.benificial.inputs.company.companyName"), beneficial.companyName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.company.socialCapital"), String(beneficial.socialCapital) ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.company.socialCapitalCurrency"), String(beneficial.socialCapitalCurrency) ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.company.postalAddress"), beneficial.postalAddress ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.company.legalForm"), beneficial.legalForm ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.company.registrationNumber"), beneficial.registrationNumber ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.company.specialization"), beneficial.specialization ?? ""],
			["", t("pages.editionContract.popups.benificial.responsableSociete")],
			[t("pages.editionContract.popups.benificial.inputs.person.firstname"), beneficial.firstName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.lastname"), beneficial.lastName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.email"), beneficial.email ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.jobTitle"), beneficial.jobTitle ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.address"), beneficial.address?.addressLine ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.dateOfBirth"), String(beneficial.dateOfBirth) ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.person.placeOfBirth"), beneficial.placeOfBirth ?? ""],

		)
	} else if (beneficial.type == 'Minor') {
		out.push(
			[t("pages.editionContract.popups.benificial.inputs.minor.minorFirstName"), beneficial.minorFirstName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.minorLastName"), beneficial.minorLastName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.minorDateOfBirth"), String(beneficial.minorDateOfBirth) ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.minorPlaceOfBirth"), beneficial.minorPlaceOfBirth ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.minorOrderNumber"), beneficial.minorOrderNumber ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.minorOrderDate"), String(beneficial.minorOrderDate) ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.minorOrderCourt"), beneficial.minorOrderCourt ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.minorOrderName"), beneficial.minorOrderName ?? ""],
			["", "Tutor"],
			[t("pages.editionContract.popups.benificial.inputs.minor.firstName"), beneficial.firstName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.lastName"), beneficial.lastName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.email"), beneficial.email ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.jobTitle"), beneficial.jobTitle ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.addressLine"), beneficial.address?.addressLine ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.dateOfBirth"), String(beneficial.dateOfBirth) ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.minor.placeOfBirth"), beneficial.placeOfBirth ?? ""],
		)
	}
	if (beneficial.hasAttorney) {
		out.push(
			["", t("pages.editionContract.popups.benificial.attorney")],
			[t("pages.editionContract.popups.benificial.inputs.attorney.attorneyFirstName"), beneficial.attorney.firstName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.attorney.attorneyLastName"), beneficial.attorney.lastName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.attorney.attorneyCin"), beneficial.attorney.cin ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.attorney.attorneyCinDeliveryDate"), String(beneficial.attorney.cinDeliveryDate) ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.attorney.attorneyCinDeliverPlace"), beneficial.attorney.cinDeliverPlace ?? ""],
		)
	}
	if (beneficial.type == 'Person' && beneficial.hasSpouse) {
		out.push(
			["", "spouse"],
			[t("pages.editionContract.popups.benificial.inputs.joint.spouseFirstName"), beneficial.spouse.firstName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.joint.spouseLastName"), beneficial.spouse.lastName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.joint.spouseCin"), beneficial.spouse.cin ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.joint.spouseCinDeliveryDate"), String(beneficial.spouse.cinDeliveryDate) ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.joint.spouseCinDeliverPlace"), beneficial.spouse.cinDeliverPlace ?? ""],
		)
	}
	if (beneficial.type == 'Person' && beneficial.hasSpouseAttorney) {
		out.push(
			["", `${t("pages.editionContract.popups.benificial.attorney")} (${t("pages.editionContract.popups.benificial.spouse")}):`],
			[t("pages.editionContract.popups.benificial.inputs.attorney.attorneyFirstName"), beneficial.spouseAttorney.firstName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.attorney.attorneyLastName"), beneficial.spouseAttorney.lastName ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.attorney.attorneyCin"), beneficial.spouseAttorney.cin ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.attorney.attorneyCinDeliveryDate"), String(beneficial.spouseAttorney.cinDeliveryDate) ?? ""],
			[t("pages.editionContract.popups.benificial.inputs.attorney.attorneyCinDeliverPlace"), beneficial.spouseAttorney.cinDeliverPlace ?? ""],
		)
	}
	//signature
	out.push(
		["", "Signature"],
	)
	switch (beneficial.type) {
		case 'Person':
			out.push(
				[beneficial.fullName, "\n\n\n"],
			)
			if (beneficial.hasAttorney) {
				out.push(
					[`${beneficial.attorney.firstName} ${beneficial.attorney.lastName}`, "\n\n\n"],
				)
			}
			if (beneficial.hasSpouse) {
				out.push(
					[`${beneficial.spouse.firstName} ${beneficial.spouse.lastName}`, "\n\n\n"],
				)
			}
			if (beneficial.hasSpouseAttorney) {
				out.push(
					[`${beneficial.spouseAttorney.firstName} ${beneficial.spouseAttorney.lastName}`, "\n\n\n"],
				)
			}
			break;
		case 'Company':
			out.push(
				[beneficial.fullName, "\n\n\n"],
			)
			if (beneficial.hasAttorney) {
				out.push(
					[`${beneficial.attorney.firstName} ${beneficial.attorney.lastName}`, "\n\n\n"],
				)
			}
			break;
		case 'Minor':
			out.push(
				[beneficial.minorFullName, "\n\n\n"],
				[beneficial.fullName, "\n\n\n"],
			)
			if (beneficial.hasAttorney) {
				out.push(
					[`${beneficial.attorney.firstName} ${beneficial.attorney.lastName}`, "\n\n\n"],
				)
			}
			break;
		default:
			break;
	}
	return out


}
export const serializeParamValuesForm = (paramValues, template, fileNames, beneficialsMap, t: (string) => string, language: LanguageType) => {
	const params = mergeParamsForm(template)

	let spreadsheets: Record<string, string[][]> = {
		"default": [
			['Page_Name', 'Name', 'Type', 'Label', 'Value'],
		],
	}
	let paramMap: Record<string, boolean> = {}
	let tableCounter = 1
	for (const param of params) {
		if (paramMap[param.name]) {
			continue
		}
		paramMap[param.name] = true
		const value = paramValues[param.name]
		const { clauseName, name, type, label } = param
		const row = [clauseName, name, type, label]
		switch (param.type) {
			case 'beneficial':
				const beneficial = beneficialsMap[value]
				if (beneficial) {
					spreadsheets[virtualbeneficialGetters.fullName(beneficial, t, language)] = serializeBeneficial(beneficial, t, language)
				}
				spreadsheets['default'].push([...row, beneficial ? virtualbeneficialGetters.fullName(beneficial, t, language) : ""])
				break;
			case 'beneficial[]':
				const beneficials = Array.isArray(value) ? value.map(bId => beneficialsMap[bId]) : []
				for (const beneficial of beneficials || []) {
					spreadsheets[virtualbeneficialGetters.fullName(beneficial, t, language)] = serializeBeneficial(beneficial, t, language)
				}
				spreadsheets['default'].push([...row, beneficials?.map(b => `BENEFICIAL-${b.id}`)?.join(", ") ?? ""])
				break;
			case 'table':
				const rows = value ?? []
				spreadsheets[`TABLE-${tableCounter}`] = [
					param.args && param.args.map(arg => arg.header),
					...rows
				]
				spreadsheets['default'].push([...row, `TABLE-${tableCounter}`])
				++tableCounter
				break;
			case 'csv':
				const [csvTransposed, csvRows, csvName] = value ?? [false, [], ""]
				spreadsheets[`TABLE-${tableCounter}`] = csvRows
				spreadsheets['default'].push([...row, `TABLE-${tableCounter}`])
				++tableCounter
				break;
			case 'file':
				if (value instanceof File) {
					spreadsheets['default'].push([...row, value.name ?? ""])
				} else {
					const fileName = fileNames[value]
					spreadsheets['default'].push([...row, fileName ?? ""])
				}
				break;
			case 'list':
				spreadsheets['default'].push([...row, value?.map(idx => idx + 1)?.join(",") ?? ""])
				break;
			case 'enum':
				if (param.args) {
					const arg = param.args[value]
					spreadsheets['default'].push([...row, arg?.option ?? ""])
				}
				break;
			case 'boolean':
				spreadsheets['default'].push([...row, (value == true && "Oui") || (value == false && "Non") || ""])
				break;
			case 'comment':
			case 'date':
			case 'number':
			case 'string':
			case 'property':
				spreadsheets['default'].push([...row, value ?? ""])
				break;
			default:
				break;
		}
	}
	return cellsToXLSXWorkbook(spreadsheets)

};

export const getFormExportData = (form: FormEntity, t: (string) => string, language: LanguageType): FormDocxExportText => {

	const { paramValues, beneficialsMap, fileNames } = form
	let tableCounter = 1
	let data: FormDocxExportText = {
		name: form.name,
		values: paramValues,
		pages: [],
		tables: [],
		beneficials: [],
	}
	const template = getRenderedFormTemplate(form)
	template.pages.forEach((page) => {
		let pageData: FormDocxExportText['pages'][number] = {
			name: page.name,
			rows: [],
		}
		for (const param of page.params) {
			let paramMap: Record<string, boolean> = {}
			if (paramMap[param.name]) {
				continue
			}
			paramMap[param.name] = true
			const value = paramValues[param.name]
			const { label } = param
			switch (param.type) {
				case 'beneficial':
					const beneficial = beneficialsMap[value]
					if (beneficial) {
						if (!data.beneficials.find(b => b.name == virtualbeneficialGetters.fullName(beneficial, t, language))) {
							const beneficialCells = serializeBeneficial(beneficial, t, language)
							data.beneficials.push({
								name: virtualbeneficialGetters.fullName(beneficial, t, language),
								rows: beneficialCells.map(row => ({
									label: row[0],
									value: row[1],
								}))
							})
						}
					}
					pageData.rows.push({
						label,
						value: beneficial ? virtualbeneficialGetters.fullName(beneficial, t, language) : "",
					})
					break;
				case 'beneficial[]':
					const beneficials = Array.isArray(value) ? value.map(bId => beneficialsMap[bId]) : []
					for (const beneficial of beneficials || []) {
						if (!data.beneficials.find(b => b.name == virtualbeneficialGetters.fullName(beneficial, t, language))) {
							const beneficialCells = serializeBeneficial(beneficial, t, language)
							data.beneficials.push({
								name: virtualbeneficialGetters.fullName(beneficial, t, language),
								rows: beneficialCells.map(row => ({
									label: row[0],
									value: row[1],
								}))
							})
						}
					}
					pageData.rows.push({
						label,
						value: beneficials?.map(b => `BENEFICIAL-${b.id}`)?.join(", ") ?? "",
					})
					break;
				case 'table':
				case 'csv':
					if (value) {
						const renderSegments = getRenderSegments([[
							"0", param.name, SegmentedTextType.PARAM, {}
						]], form.paramValues, {}, {}, [param], () => "", 'fr')
						let docxml = renderTableToDocXml(renderSegments[0], form)
						docxml = closeDocxmlText(docxml)
						data.tables.push({
							name: `TABLE-${tableCounter}`,
							xmlText: docxml,
						})
						pageData.rows.push({
							label,
							value: `TABLE-${tableCounter++}`,
						})
					} else {
						pageData.rows.push({
							label,
							value: "",
						})
					}
					break;
				case 'file':

					if (value instanceof File) {
						pageData.rows.push({
							label,
							value: value.name,
						})
					} else {
						const fileName = fileNames[value]
						pageData.rows.push({
							label,
							value: fileName,
						})
					}
					break;
				case 'list':
					pageData.rows.push({
						label,
						value: value?.map(idx => idx + 1)?.join(","),
					})
					break;
				case 'enum':
					if (param.args) {
						const arg = param.args[value]
						pageData.rows.push({
							label,
							value: arg?.option ?? "",
						})
					}
					break;
				case 'boolean':
					pageData.rows.push({
						label,
						value: (value == true && "Oui") || (value == false && "Non") || "",
					})
					break;
				case 'comment':
				case 'date':
				case 'number':
				case 'string':
				case 'property':
					pageData.rows.push({
						label,
						value,
					})
					break;
				default:
					break;
			}
		}
		data.pages.push(pageData)

	})
	return data

};