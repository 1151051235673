import { BeneficialBaseEntity } from "./domain/entities";

export const REACT_APP_DEV_MODE = process.env.REACT_APP_DEV_MODE ? true : false;
export const DEV_MODE = process.env.DEV_MODE ? true : false;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const REACT_APP_LISTENER_ON = process.env.REACT_APP_LISTENER_ON ? true : false;
export const REACT_APP_FORM_AI_ON = process.env.REACT_APP_FORM_AI_ON ? true : false;
export const REACT_APP_DISABLE_CSV = process.env.REACT_APP_DISABLE_CSV ? true : false;
export const RAG_BASE_URL = process.env.REACT_APP_RAG_BASE_URL;
export const COMPANY_CODE = process.env.REACT_APP_COMPANY_CODE;
export const APP_ENV = process.env.REACT_APP_ENV;


export const config = {
  defaultBeneficial: {
    maritalStatus: 'Single',
    nationality: 'TUN',
    gender: 'M',
  } as BeneficialBaseEntity
}

