import "./projectList.scss";
import "../index.scss";
import { BsSearch } from "react-icons/bs";
import { useEffect, useState } from "react";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { useTranslation } from "../../contexts/TranslationProvider";
import Loading from "../../components/common/Loading";
import { ProjectTemplateEntity, TypeLevel1Entity } from "../../domain/entities";
import { ProjectTemplateClient } from "../../services/api/ProjectTemplateClient";
import { getName } from "../../helpers/Translation";
import ProjectTemplateCard from "../../components/common/newDesign/ProjectTemplateCard";
import LayoutFilter from "../../components/LayoutFilter";
import ProjectTemplateTableItem from "../../components/common/newDesign/ProjectTemplateCard/ProjectTemplateTableItem";
const ProjectTemplates = () => {
  const [loading, setLoading] = useState(true);
  const [projectTemplate, setProjectTemplate] = useState<
    ProjectTemplateEntity[]
  >([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [updateInProjectsList, setProjectListChanged] = useState(false);

  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const [displayCards, setDisplayCards] = useState("grid");
  const projectTemplateClient = new ProjectTemplateClient(apiClient);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await projectTemplateClient.getAll();
        const dataTemplate = await projectTemplateClient.getAll();
        setProjectTemplate(dataTemplate?.rows);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching contracts: ", error);
        setLoading(false);
      }
    };
    fetchData();
    setProjectListChanged(false);
  }, [updateInProjectsList]);
  const filteredProjects = projectTemplate?.filter((project) =>
    project?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortedProjects = filteredProjects.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );
  const projectsPerLevel1: Record<
    TypeLevel1Entity["name"],
    ProjectTemplateEntity[]
  > = {};

  const levels1Names = [
    ...new Set(
      filteredProjects.map((template) => getName(template.level1, language))
    ),
  ];
  levels1Names.forEach((level1Name) => (projectsPerLevel1[level1Name] = []));

  sortedProjects.forEach((template) => {
    projectsPerLevel1[getName(template.level1, language)].push(template);
  });
  const handleDesactivateProjectTemplate=async(id)=>{
    await projectTemplateClient.desactivate(id);
    setProjectListChanged(true)
  }
  const handleLayoutChange = (layout) => {
    setDisplayCards(layout);
  };
  return (
    <div className="projects px-3 pt-2">
      <section id="projets">
        <div className="search-zone">
          <div className="d-flex mt-4 ps-1 align-items-center justify-content-between">
            <div className="search-bar-projectTemplate">
              <BsSearch
                className="search-icon search-project"
                style={{ marginRight: language == "ar" && "89%"}}
              />
              <input
                type="text"
                placeholder={t("pages.ProjectTemplates.search")}
                className="search-input"
                style={{ minWidth: "350px" }}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
        <LayoutFilter onLayoutChange={handleLayoutChange} />

        <div className="mt-5">
          {loading ? (
            <Loading height="75vh" />
          ) : displayCards=="grid"?(
            <div className="row gap-5">
              {levels1Names?.map((level1Name) => (
                <div className="col projects-space" key={level1Name}>
                  <h3 className="m-2 projects-space-title">{level1Name}</h3>
                  <div className="project-group-container">
                    <div className="projects-per-level">
                      {projectsPerLevel1[level1Name]?.map((item) => (
                        <div key={item?.id} className="m-2">
                          <div className="project-group">
                            <ProjectTemplateCard
                              onDesactivate={item?.activated ? () => handleDesactivateProjectTemplate(item.id):undefined}
                              data={item}
                              key={item?.id}
                              setProjectListChanged={setProjectListChanged}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ):
          <table className="template-table">
          <thead>
            <tr>
              <th className={language === "ar" ? "text-right" : ""}>
                {t("pages.groupContracts.tableHeaders.group")}
              </th>
              <th className={language === "ar" ? "text-right" : ""}>
                {t("pages.groupContracts.tableHeaders.name")}
              </th>
              <th className={language === "ar" ? "text-right" : ""}>
                {t("pages.groupContracts.tableHeaders.type")}
              </th>
              <th className={language === "ar" ? "text-right" : ""}>
                {t("pages.groupContracts.tableHeaders.status")}
              </th>
              <th className={language === "ar" ? "text-right" : ""}>
                {t("pages.groupContracts.tableHeaders.actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            <div>
      {levels1Names.map((level1Name) =>
        projectsPerLevel1[level1Name]
          .slice()
          .sort(
            (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          )
          .map((item) => {
            return (
              <ProjectTemplateTableItem
              onDesactivate={item?.activated ? () => handleDesactivateProjectTemplate(item.id):undefined}
              data={item}
              key={item?.id}
              group={level1Name}
              setProjectListChanged={setProjectListChanged}
              />
            );
          })
      )}
      </div>
    </tbody>
    
        </table>
          }
        </div>
      </section>
    </div>
  );
};

export default ProjectTemplates;
